import './TableCell.css'

const TableCell = props => {

    const justify = props.justify ? props.justify : 'left' //left as default
    return (
        <div className='TableCell' style={{justifySelf: justify, paddingRight: justify === 'center' ? '0' : '10px'}} {...props}>
            {props.children}
        </div>
    )
}

export default TableCell