import React, { useState } from "react";
import {
  MButtonOrange,
  SButtonLightGrey,
} from "../../../../../styledComponents/Buttons";
import "../KadriCards.css";
import "react-calendar/dist/Calendar.css";
import { ID_CARD, OLD_PASSPORT } from "../../../../../redux/types";
import IdentificationData from "./KadriCardsPersonBlocks/IdentificationData";
import AddressData from "./KadriCardsPersonBlocks/AddressData";
import PassportData from "./KadriCardsPersonBlocks/PassportData";
import { InputForm, SelectForm } from "../../../../../styledComponents/Inputs";
import { useDispatch, useSelector } from "react-redux";
import InputMask from "react-input-mask";
import datePicker from "../../../../../assets/datePicker.svg";
import Calendar from "react-calendar";
import getDateAndTime from "../../../../../utilits/getDateAndTime";
import DatePickerFragment from "../../../../../components/datePicker/datePicker";

export let KadriPagePersonal = ({
  formik,
  values,
  isValid,
  handleChange,
  currentWorker,
  setCurrentWorker,
  isFetching,
  isAddModal,
  setIsEdited,
  isEdited,
  id,
}) => {
  const [dateRelease, setDateRelease] = useState(null);
  const positions = useSelector(
    (state) => state.cadrDocumentsReducer.positions
  );

  const [selectedPassport, setSelectedPassport] = useState(
    currentWorker
      ? values.passport.length === 9
        ? ID_CARD
        : OLD_PASSPORT
      : ID_CARD
  );

  return (
    <form
      autoComplete="off"
      className="table__container__full"
      onSubmit={formik.handleSubmit}
    >
      <IdentificationData
        handleChange={handleChange}
        surname={values.surname}
        name={values.name}
        patronymic={values.patronymic}
        RNOKPP={values.RNOKPP}
        workerId={id}
        setSelectedPassport={setSelectedPassport}
        mobilePhone={values.mobilePhone}
        resours={values.resours}
        methodInvite={values.methodInvite}
        workPhoneNumber={values.workPhoneNumber}
        sex={values.sex}
        birthday={values.birthday}
        isEdited={isEdited}
        setIsEdited={setIsEdited}
        setFieldValue={formik.setFieldValue}
      />
      <AddressData
        street={values.street}
        place={values.place}
        handleChange={handleChange}
        house={values.house}
        apartments={values.apartments}
        setFieldValue={(id, e) => {
          formik.setFieldValue(id, e);
          setIsEdited(true);
        }}
      />
      <PassportData
        handleChange={handleChange}
        authority={values.authority}
        dateOfIssue={values.dateOfIssue}
        dateRelease={dateRelease}
        nationality={values.nationality}
        passport={values.passport}
        selectedPassport={selectedPassport}
        setDateRelease={setDateRelease}
        setFieldValue={formik.setFieldValue}
        setIsEdited={setIsEdited}
        setSelectedPassport={setSelectedPassport}
      />
      {isAddModal && (
        <div className="table__row" style={{ marginBottom: "2px" }}>
          <div style={{ paddingTop: 0 }} className="table__place">
            <span>Статус працівника</span>
          </div>
          <div
            className="table__value"
            style={{ width: "100%", maxWidth: 738, alignItems: "flex-start" }}
          >
            <div className="table__value__row" style={{ marginBottom: 0 }}>
              <div className="table__value__item">
                <span>Посада</span>
                <SelectForm
                  value={values.posada}
                  onChange={handleChange}
                  id={"posada"}
                  style={{ width: "200px" }}
                >
                  <option value="" disabled hidden>
                    Виберіть посаду
                  </option>
                  {positions
                    .sort((a, b) =>
                      a.vacancy
                        .toLowerCase()
                        .localeCompare(b.vacancy.toLowerCase(), "uk-UA", {
                          sensitivity: "accent",
                        })
                    )
                    .map((pos, index) => (
                      <option key={index} value={pos.title}>
                        {pos.title}
                      </option>
                    ))}
                </SelectForm>
              </div>
              <div className="table__value__item">
                <span>Дата виходу на роботу</span>
                <div className="edit_rent__wrapper">
                  <DatePickerFragment
                    selected={values.workForm}
                    placeholder={"дд.мм.рррр"}
                    dateFormat="dd.MM.yyyy"
                    onChange={(date) =>
                      formik.setFieldValue(
                        "workForm",
                        new Date(date).setHours(12)
                      )
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <div style={{ marginBottom: 0 }} className="table__row">
        <div className="table__place">
          <span></span>
        </div>
        <div className="table__value">
          <div className="table__value__row">
            <div className="table__value__item null">
              {currentWorker === null || currentWorker.isFullData ? (
                <>
                  {isValid && !isFetching ? (
                    <MButtonOrange
                      className="buttonDisabled"
                      style={{
                        margin: "0",
                        position: "absolute",
                        right: "30px",
                        bottom: "30px",
                      }}
                      type="submit"
                    >
                      Далі
                    </MButtonOrange>
                  ) : (
                    <SButtonLightGrey
                      className="buttonDisabled"
                      style={{
                        position: "absolute",
                        right: "30px",
                        bottom: "30px",
                      }}
                      type={"button"}
                    >
                      Далі
                    </SButtonLightGrey>
                  )}
                </>
              ) : isEdited && isValid && !isFetching ? (
                <MButtonOrange
                  style={{
                    margin: "0",
                    position: "absolute",
                    right: "30px",
                    bottom: "30px",
                  }}
                  type="submit"
                >
                  Зберегти
                </MButtonOrange>
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
      </div>
      {/* {work__4 && <div className="bg__layer"></div>}
      {work__4 && <KadriCardsWorkDoc__4 toggleWork__4={toggleWork__4} />} */}
    </form>
  );
};

KadriPagePersonal = React.memo(KadriPagePersonal);
