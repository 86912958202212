import React, {useEffect, useMemo, useRef, useState} from "react";
import {
  deleteFileObject,
  downloadFileObject,
  getDocuments,
  uploadDocuments,
  viewFilesObject,
} from "../../../api/objects";
import {SButtonGrey} from "../../../styledComponents/Buttons";
import imgDoc from "../../../assets/imgDoc.svg"
import minus from "../../../assets/minus.svg";
import plus from "../../../assets/plus.svg";
import trash from "../../../assets/trash.svg";
import printWhite from "../../../assets/printWhite.svg";
import ReactTooltip from "react-tooltip";
import download from "../../../assets/download.svg";
import {useAlert} from "react-alert";
import {WarningPopUp} from "../../PopUps/WarningPopup";
import PdfViewer from "../../../components/iframeView/iframeDoc";
import {useSelector} from "react-redux";
import Viewer from "react-viewer"
import {useReactToPrint} from 'react-to-print';

let documents = [
  'Договір оренди / суборенди', 'Договір про охорону', 'Договір субліцензії',
  'Довіреність'
]

const EditRentDocument = ({id, address, region, house}) => {

  const componentRef = useRef(null);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const [type, setType] = useState('')
  const inputRef = useRef();
  const listRef = useRef(null);
  const docActiveList = useRef(null)
  const [documentType, setDocumentType] = useState([])
  const [isFetching, setFetching] = useState(false)
  const [documentData, setDocument] = useState([]);
  const [urlViewed, setUrlViewed] = useState("");
  const [isBottom, setIsBottom] = useState(false)
  const [visible, setVisible] = React.useState(false);
  const [imageArray, setImageArray] = useState(null)
  const [indexArray, setActiveIndex] = useState(0)
  const user = useSelector(state => state.user);
  const alert = useAlert()
  const alertFunc = (message, type) => {
    alert.show(message, {type: type})
  }
  const onChange = (e, value) => {
    e.preventDefault();
    setType(value)
    inputRef.current.click();
  }


  const updateDocument = async () => {
    await getDocuments(id).then(res => {
      setDocument(res)
      setDocumentType([...new Set(res.map(el => el.type))])
    })
  }
  useEffect(updateDocument, [])

  const IframeViewDocument = () => {
    return !/\.(png|gif|tif|webp|jpg|jpge)|(.cs)/.test(urlViewed.typeFile) ?
      !/\.(pdf)/.test(urlViewed.typeFile) ? <PdfViewer url={urlViewed}/> :
        <a href={urlViewed.generateUrl} target="_blank">
          <object type="application/pdf"
                  data={urlViewed.generateUrl + "#view=Fit&toolbar=0&statusbar=0&messages=0&navpanes=0&scrollbar=0"}
                  width="100%" height="400" style={{pointerEvents: "none"}}>
            <embed src={urlViewed.generateUrl + "#view=Fit&toolbar=0&statusbar=0&messages=0&navpanes=0&scrollbar=0"}
                   type="application/pdf"/>
          </object>
        </a> :
      <img onClick={
        (e) => {
          e.preventDefault();
          setVisible(true);
        }
      } style={{cursor: "pointer", margin: "auto"}} src={urlViewed.generateUrl} alt="" width="100%"/>
  }
  const fileUpload = async (e
  ) => {
    e.preventDefault();
    await setFetching(true)
    await uploadDocuments([...e.target.files].map((file) => ({
      type,
      file
    })), id, address = {city: address, region: region, house: house}, alertFunc)
    await updateDocument()
    await setFetching(false)
  }

  const ListDocument = ({type, array}) => {
    const [openTab, setOpenTab] = useState(false);
    const toggleOpenTad = (e) => {
      e.preventDefault();
      setOpenTab(!openTab);
    }
    const downloadDocument = async (e, params) => {
      e.preventDefault();
      const res = await downloadFileObject(params._id, params.path, params.filename);
      let link = document.createElement("a");
      link.href = res.generateUrl;
      link.click();
      link.remove();
    }

    const viewDocument = async (e, params) => {
      e.preventDefault();
      const res = await viewFilesObject(params._id, params.path, params.filename);
      setUrlViewed(res)

      if (/\.(png|gif|tif|webp|jpg|jpge)|(.cs)/.test(params.filename)) {
        setImageArray([{
          src: res.generateUrl,
          alt: ""
        }])
      }
      // Нужно как-то по другому реализовать
      e.target.closest(".edit_rent-document__right").querySelectorAll(".hover-underline").forEach(element => element.style.fontWeight = "400")
      e.target.style.fontWeight = "700";
    }
    const deleteDocument = async (e, params) => {
      e.preventDefault();
      await deleteFileObject(params._id, params.path, params.filename, id)
      await updateDocument();
      alertFunc(`Документ ${params.filename} успішно видалено`, {type: 'success'})
      setUrlViewed(null)
    }


    return (
      <div className="edit_rent-document__column">
        <div style={{display: 'flex', alignItems: 'center'}}>
          <div className="document__row__header" onClick={toggleOpenTad}>
            <button className={'document__row__btn'}>
              <img src={openTab ? minus : plus}/>
            </button>
            <span>{type}</span>
          </div>
        </div>
        <div className={`document__row__content ${openTab && 'document__active'}`}>
          {array.filter(items => items.type === type).map((doc, i) => <FileInfoDoc
              filename={doc.filename}
              key={i}
              doc={doc}
              user={user}
              downloadDocument={(e) => downloadDocument(e, doc)}
              viewDocument={(e) => viewDocument(e, doc)}
              deleteDocument={(e) => deleteDocument(e, {...doc, userId: user._id})}
            />
          ).reverse()}
        </div>
      </div>)
  }
  const data = useMemo(() => documentType.map((type, i) => <ListDocument key={i}
                                                                         array={documentData}
                                                                         type={type}/>), [documentType, documentData])


  return (
    <React.Fragment>
      {<Viewer
        ref={componentRef}
        customToolbar={(toolbar) =>
          [...toolbar,
            {
              key: "print",
              onClick: () => {
                let imageNew = document.importNode(document.querySelector(".react-viewer-image"), true)
                let div = document.createElement("div");
                div.setAttribute("style", "display:flex;width:1100px;heigth:1450px;")
                imageNew.setAttribute("style", "width:100%;heigth:auto;")
                div.appendChild(imageNew)
                componentRef.current = div;
                handlePrint()
              },
              render: <img src={printWhite} width={13} height={13} alt=""/>
            }
          ]}
        visible={visible}
        zoomSpeed="0.2"
        defaultScale={1.5}
        minScale="1"
        activeIndex={indexArray}
        onClose={() => {
          setVisible(false);
        }}
        images={imageArray && imageArray}
      />}
      {isFetching && <div className={'disabled__overlay'}>
        <div className="lds-ring">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>}
      <div className="edit_rent__wrapper" style={{justifyContent: "space-between"}}>
        <div className="edit_rent__document__left"
             style={{
               minWidth: 300,
               maxHeight: 367,
               width: 300,
               overflow: "hidden",
               minHeight: 367,
               display: "flex",
               alignItems: !urlViewed ? "center" : "flex-start",
               justifyContent: "center",
               paddingRight: "20px",
               boxSizing: "content-box",
               borderRight: "1px solid #E4E4E4"
             }}>
          {urlViewed ? <IframeViewDocument/> : <img src={imgDoc} alt=""/>}
        </div>
        <div ref={docActiveList} className="edit_rent-document__right"
             style={{width: "100%", maxHeight: 376, paddingLeft: "50px"}}>
          {data}
          <div ref={listRef} className={'pos-rel'} style={{width: "max-content"}}>
            <input onChange={fileUpload} type="file" style={{display: 'none'}} ref={inputRef}
                   multiple/>
            <SButtonGrey onClick={(e) => {
              e.preventDefault();
              if (listRef.current.offsetTop > 300) setIsBottom(true)
              else setIsBottom(false)
            }} style={{
              fontSize: '13px',
              padding: '12px 18px',
              color: 'black',
              marginRight: 0,
              whiteSpace: "no-wrap"
            }}>Додати документ</SButtonGrey>
            <div style={{
              width: '250px',
              left: 0,
              bottom: isBottom ? '40px' : '',
              boxShadow: `0px ${isBottom ? '-8' : '8'}px 16px 0px rgb(0 0 0 / 20%)`
            }} className={"table__dropdown__content"}>
              {documents.map((item, index) => (
                <button key={index} onClick={(e) => onChange(e, item)}
                        className={'submenu__dropdown__btn'}>
                  {item}
                </button>))}
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>)
}

const FileInfoDoc = ({filename, deleteDocument, downloadDocument, viewDocument, doc, user}) => {
  const [deleteItem, setDeletedItem] = useState(false);

  return <div className="document__row__content__item">
    <span className={'hover-underline'} onClick={viewDocument}>{filename}</span>
    {user._id === doc.user && <button
      data-tip data-for={`delete${filename}`}
      style={{marginRight: "6px"}} className={'document__row__icon'} onClick={(e) => {
      e.preventDefault();
      setDeletedItem(true)
    }}>
      <img src={trash}/>
    </button>}
    <ReactTooltip backgroundColor={'#52616E'} effect={'solid'} id={`delete${filename}`}>
      Видалити
    </ReactTooltip>
    <button
      data-tip data-for={`download${filename}`}
      className={'document__row__icon'} onClick={downloadDocument}>
      <img src={download} style={{width: "14px", height: "14px"}}/>
    </button>
    <ReactTooltip backgroundColor={'#52616E'} effect={'solid'} id={`download${filename}`}>
      Завантажити
    </ReactTooltip>

    {deleteItem &&
      <WarningPopUp
        onDelete={deleteDocument}
        toggleWarningModal={(e) => {
          e.preventDefault();
          setDeletedItem(false);
        }}
        isNewText={false}
        name={`Файл <span>${filename}</span> буде видаленно`}
      />}
  </div>
}

export default EditRentDocument;
