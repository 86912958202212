import React, { useCallback, useEffect, useRef, useState } from "react";

import close from "../../../../../assets/close.svg";

import "../KadriCards.css";
import { KadriPageSpecialQuota } from "./KadriPageSpecialQuota";
import KadriPageDocument from "./KadriPageDocument";
import { KadriPageHistory } from "./KadriPageHistory";
import { KadriPagePersonal } from "./KadriPagePersonal";
import { useAlert } from "react-alert";
import { connect, useSelector } from "react-redux";
import KadriPreviewDocument from "./KadriPreviewDocument";
import { setPreviewFile } from "../../../../../redux/workers/worker-actions";
import {
  docTypes,
  PROF_EDUCATION,
  SECOND_EDUCATION,
} from "../../../../../redux/types";
import { Formik, useFormik } from "formik";
import { store } from "../../../../../index";
import addWorker from "../../../../../redux/actions/workers/addWorker";
import { editSecondaryWorkerInfo } from "../../../../../redux/actions/workers/editSecondaryWorkerInfo";
import PassportModal from "../../../PassportModal";
import phoneValidation from "../../../../../validations/phoneValidation";
import KadriPageConditionsWork from "./KadriPageConditionsWork";
import moment from "moment";

const TabContent = ({
  fullName,
  setIsWorker,
  isWorker,
  typeId,
  typeModal,
  objectId,
  active,
  isAddModal,
  setIsPassport,
  page,
  closeModal,
  id,
  workerId,
  setActive,
  files,
  setFiles,
  openFile,
  isPressedFile,
  educationRows,
  setEducationRows,
  workRows,
  setWorkRows,
  familyRows,
  setFamilyRows,
  isPersonal,
  ...props
}) => {
  const [isFetching, setIsFetching] = useState(false);
  const [isEdited, setIsEdited] = useState(false);
  const alert = useAlert();
  const [currentStatus, setCurrentStatus] = useState("");
  const currentWorkerData = useSelector((state) => {
    return state.currentWorker;
  });
  console.log(currentWorkerData);
  const alertFunc = (message, type) => {
    alert.show(message, { type: type });
    setIsFetching(false);
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      surname: currentWorkerData?.surname ?? "",
      name: currentWorkerData?.name ?? "",
      patronymic: currentWorkerData?.patronymic ?? "",
      mobilePhone: currentWorkerData?.mobilePhone ?? "",
      resours: currentWorkerData?.resours ?? "",
      methodInvite: currentWorkerData?.methodInvite ?? "",
      workPhoneNumber: currentWorkerData?.workPhoneNumber ?? "",
      RNOKPP: currentWorkerData ? currentWorkerData.RNOKPP : "", // или null, если нажата галочка "без РНОКПП"
      sex: currentWorkerData?.sex ?? "",
      place: currentWorkerData?.place ?? null,
      birthday: currentWorkerData?.birthday ?? "",
      street: currentWorkerData?.street ?? "",
      posada: currentWorkerData?.postInfo.posada ?? "",
      workForm: currentWorkerData?.postInfo.workForm
        ? new Date(moment(currentWorkerData?.postInfo.workForm, "DD.MM.YYYY"))
        : null,
      house: currentWorkerData?.house ?? "",
      apartments: currentWorkerData?.apartments ?? "",
      passport: currentWorkerData?.passport ?? "",
      authority: currentWorkerData?.authority ?? "",
      dateOfIssue: currentWorkerData?.dateOfIssue ?? "",
      nationality: currentWorkerData?.nationality ?? "Україна",
      city: currentWorkerData?.street ?? "",
    },
    onSubmit: async (values) => {
      setIsFetching(true);
      if (currentWorkerData?.isFullData) {
        store.dispatch(
          addWorker(
            {
              ...values,
              _id: employerId,
              workerId: currentWorkerData.workerId,
              searchId: currentWorkerData.searchId,
              city: values.street,
              objectId: objectId ? objectId : null,
            },
            alertFunc,
            employerId,
            () => setActive(1)
          )
        ); // just add workers
        return;
      }
      if (currentWorkerData) {
        store.dispatch(
          addWorker(
            {
              ...values,
              _id: currentWorkerData._id,
              objectId: objectId ? objectId : null,
            },
            alertFunc,
            employerId,
            () => setActive(1),
            true
          )
        ); // edit workers
        closeModal("personEdit");
        return;
      }
      store.dispatch(
        addWorker(
          {
            ...values,
            _id: employerId,
            city: values.street,
            objectId: objectId ? objectId : null,
          },
          alertFunc,
          employerId,
          () => setActive(1)
        )
      );
    },
  });
  let { values } = formik;
  let isValid =
    values.surname &&
    values.name &&
    phoneValidation(values.mobilePhone) &&
    (values.RNOKPP || values.RNOKPP === null) &&
    values.sex &&
    values.birthday &&
    values.place &&
    values.place.street &&
    values.place.street.length > 1 &&
    values.passport &&
    values.authority &&
    values.dateOfIssue &&
    values.nationality &&
    // values.resours &&
    // values.methodInvite &&
    values.house &&
    (!isAddModal || values.posada) &&
    (!isAddModal || values.workForm);
  let handleChange = useCallback((e) => {
    setIsEdited(true);
    formik.handleChange(e);
  }, []);

  const employerId = !isPersonal.check
    ? window.location.pathname.slice(
        window.location.pathname.lastIndexOf("/") + 1
      )
    : isPersonal.id;
  const getEducationValues = () => {
    // get one object with education values from all objects is array
    let educationValues = [];
    let objectValues = currentWorkerData?.education
      ? currentWorkerData.education.map((e, index) => ({
          [`title${educationRows[index]?.id}`]: e.title,
          [`atestat${educationRows[index]?.id}`]: e.atestat,
          [`yearRelease${educationRows[index]?.id}`]: e.yearRelease,
          [`speciality${educationRows[index]?.id}`]: e.speciality,
          [`qualification${educationRows[index]?.id}`]: e.qualification,
          [`power${educationRows[index]?.id}`]: e.power,
        }))
      : [];
    for (let obj of objectValues) {
      educationValues = { ...educationValues, ...obj };
    }
    return educationValues;
  };
  useEffect(() => {
    if (currentWorkerData) {
      currentWorkerData.sex = values.sex;
    }
  }, [values.sex]);

  const getWorkValues = () => {
    // get one object with work values from all objects is array
    let workValues = [];
    let objectValues = currentWorkerData?.works
      ? currentWorkerData.works.map((w, index) => ({
          [`worker${workRows[index]?.id}`]: w.worker,
          [`position${workRows[index]?.id}`]: w.position,
          [`reason${workRows[index]?.id}`]: w.reason,
          [`dateDismissal${workRows[index]?.id}`]: w.dateDismissal,
        }))
      : [];
    for (let obj of objectValues) {
      workValues = { ...workValues, ...obj };
    }
    return workValues;
  };
  const getFamilyValues = () => {
    // get one object with work values from all objects is array
    let familyValues = [];
    let objectValues = currentWorkerData?.family
      ? currentWorkerData.family.map((w, index) => ({
          [`familyMember${familyRows[index]?.id}`]: w.familyMember,
          [`PIB${familyRows[index]?.id}`]: w.PIB,
          [`birthYear${familyRows[index]?.id}`]: w.birthYear,
        }))
      : [];
    for (let obj of objectValues) {
      familyValues = { ...familyValues, ...obj };
    }
    return familyValues;
  };
  useEffect(() => {
    if (currentWorkerData) {
      if (
        Object.entries(getFamilyValues())
          .map(([k, value]) => value)
          .indexOf(currentWorkerData.sex === "Ж" ? "чоловік" : "дружина") !== -1
      ) {
        setCurrentStatus(
          currentWorkerData.sex === "Ж" ? "заміжня" : "одружений"
        );
      } else {
        setCurrentStatus(
          currentWorkerData.sex === "Ж" ? "незаміжня" : "неодружений"
        );
      }
    }
  }, [currentWorkerData]);
  return (
    <div
      style={{ overflowY: page === "document" ? "visible" : "scroll" }}
      className="tabcontent"
    >
      {!currentWorkerData && (
        <PassportModal
          setIsPassport={setIsPassport}
          setFieldValue={formik.setFieldValue}
        />
      )}
      <Formik
        autoComplete="off"
        initialValues={{
          ...getEducationValues(),
          ...getWorkValues(),
          ...getFamilyValues(),
        }}
        onSubmit={(values) => {
          setIsFetching(true);
          let body = {
            id: currentWorkerData._id,
            education: educationRows.map((e) => ({
              title: values[`title${e.id}`],
              atestat: values[`atestat${e.id}`],
              yearRelease: values[`yearRelease${e.id}`],
              speciality:
                e.type === SECOND_EDUCATION
                  ? values[`speciality${e.id}`]
                  : null,
              qualification:
                e.type === SECOND_EDUCATION
                  ? values[`qualification${e.id}`]
                  : null,
              power: e.type === PROF_EDUCATION ? values[`power${e.id}`] : null,
              type: e.type,
              education: e.education,
            })),
            works: workRows.map((e) => ({
              worker: values[`worker${e.id}`],
              position: values[`position${e.id}`],
              reason: values[`reason${e.id}`],
              dateDismissal: values[`dateDismissal${e.id}`],
            })),
            family: familyRows.map((e) => ({
              familyMember: values[`familyMember${e.id}`],
              PIB: values[`PIB${e.id}`],
              birthYear: values[`birthYear${e.id}`],
            })),
          };
          props.editSecondaryWorkerInfo(alertFunc, body, employerId, () =>
            setActive(2)
          );
        }}
      >
        {({ values, handleChange, handleSubmit, setFieldValue }) => (
          <>
            {page === "specialQuota" && (
              <KadriPageSpecialQuota
                openNextTab={() => setActive(2)}
                closeModal={closeModal}
                educationRows={educationRows}
                setActive={setActive}
                setEducationRows={setEducationRows}
                workRows={workRows}
                setWorkRows={setWorkRows}
                familyRows={familyRows}
                setFamilyRows={setFamilyRows}
                values={values}
                handleChange={handleChange}
                handleSubmit={handleSubmit}
                setFieldValue={setFieldValue}
                setIsEdited={setIsEdited}
                isEdited={isEdited}
                currentStatus={currentStatus}
                setCurrentStatus={setCurrentStatus}
              />
            )}
          </>
        )}
      </Formik>
      {page === "conditionsWork" && (
        <KadriPageConditionsWork
          closeModal={closeModal}
          id={currentWorkerData._id}
          workerId={id}
          postAll={currentWorkerData}
          postInfo={currentWorkerData.postInfo}
        />
      )}

      {page === "personData" && (
        <KadriPagePersonal
          openNextTab={() => setActive(1)}
          closeModal={closeModal}
          id={id}
          formik={formik}
          values={values}
          isValid={isValid}
          handleChange={handleChange}
          isEdited={isEdited}
          isAddModal={isAddModal}
          currentWorker={currentWorkerData}
          isFetching={isFetching}
          setIsEdited={setIsEdited}
        />
      )}

      {page === "document" && (
        <KadriPageDocument
          id={currentWorkerData._id}
          isWorker={isWorker}
          typeModal={typeModal}
          typeId={typeId}
          isPersonal={isPersonal}
          values={values}
          fullName={fullName}
          openNextTab={() => setActive(3)}
          closeModal={closeModal}
        />
      )}
      {page === "history" && <KadriPageHistory />}
    </div>
  );
};

export let KadriCardsPersonEdit = ({
  currentWorker,
  fullName,
  isWorker,
  objectId,
  typeModal,
  typeId,
  setIsWorker,
  indexTab = null,
  ...props
}) => {
  const alert = useAlert();
  const modal = useRef(null);
  const [files, setFiles] = useState([]);
  const { items, closeModal, screen, isAddModal, isPersonal, workersDisabled } =
    props;
  const id = !isPersonal.check
    ? window.location.pathname.slice(
        window.location.pathname.lastIndexOf("/") + 1
      )
    : props.id;
  const [active, setActive] = React.useState(
    indexTab ? indexTab : screen === true ? 0 : screen
  );
  const [isPressedFile, setIsPressedFile] = useState(false);
  const [isPassport, setIsPassport] = useState(false);
  const [educationRows, setEducationRows] = useState(
    currentWorker?.education
      ? currentWorker.education.map((e, i) => ({
          type: e.type,
          education: e.education,
          id: Date.now() + i,
        }))
      : []
  );
  const [workRows, setWorkRows] = useState(
    currentWorker?.works
      ? currentWorker.works.map((w, i) => ({ id: Date.now() + i }))
      : []
  );
  const [familyRows, setFamilyRows] = useState(
    currentWorker?.family
      ? currentWorker.family.map((f, i) => ({ id: Date.now() + i }))
      : []
  );

  const openTab = (e) => {
    if (currentWorker) setActive(+e.target.dataset.index);
    else {
      if (+e.target.dataset.index < active) {
        setActive(+e.target.dataset.index);
      } else {
        alert.show(
          'Заповніть необхідну інформацію в цьому вікні і натисніть "Далі"',
          { type: "error" }
        );
      }
    }
  };
  useEffect(() => {
    workersDisabled &&
      modal.current
        .querySelectorAll(
          "select,input:not([type='file']),button:not(.tablinks,.buttonDisabled,.document_button_add,.submenu__dropdown__btn)"
        )
        .forEach((el) => {
          el.disabled = true;
        });
  }, [active]);
  return (
    <>
      <div
        ref={modal}
        style={{
          display:
            docTypes.indexOf(props.previewFile?.fileType) !== -1 &&
            isPressedFile
              ? "none"
              : "block",
        }}
        className={
          currentWorker || isPassport
            ? "tab__modal"
            : "tab__modal modalPassport"
        }
      >
        <div className="tab">
          <div className="tab__btn">
            {items.map((n, i) => {
              if (n.page !== "conditionsWork") {
                return (
                  <button
                    className={`tablinks ${i === active ? "active" : ""}`}
                    onClick={openTab}
                    data-index={i}
                  >
                    {n.title}
                  </button>
                );
              } else {
                return (
                  currentWorker && (
                    <button
                      className={`tablinks ${i === active ? "active" : ""}`}
                      onClick={openTab}
                      data-index={i}
                    >
                      {n.title}
                    </button>
                  )
                );
              }
            })}
          </div>
          <div
            className="tab__close"
            onClick={() => {
              closeModal("personEdit");
            }}
          >
            <img src={close} alt="close icon" />
          </div>
          <div className="tab__background"></div>
        </div>
        {items[active] && (
          <TabContent
            {...items[active]}
            fullName={fullName}
            typeModal={typeModal}
            typeId={typeId}
            isAddModal={isAddModal}
            setIsWorker={setIsWorker}
            isWorker={isWorker}
            objectId={objectId}
            isPersonal={isPersonal}
            setIsPassport={setIsPassport}
            openFile={() => setIsPressedFile(true)}
            isPressedFile={isPressedFile}
            closeModal={() => closeModal("personEdit")}
            id={id}
            active={active}
            setActive={setActive}
            files={files}
            setFiles={setFiles}
            educationRows={educationRows}
            setEducationRows={setEducationRows}
            workRows={workRows}
            setWorkRows={setWorkRows}
            familyRows={familyRows}
            setFamilyRows={setFamilyRows}
            editSecondaryWorkerInfo={props.editSecondaryWorkerInfo}
          />
        )}
      </div>
      {docTypes.indexOf(props.previewFile?.fileType) !== -1 &&
        isPressedFile && (
          <KadriPreviewDocument
            file={props.previewFile}
            closeModal={() => {
              setIsPressedFile(false);
            }}
          />
        )}
    </>
  );
};

const mapStateToProps = (state) => ({
  previewFile: state.workersReducer.previewFile,
  currentWorker: state.currentWorker,
});

KadriCardsPersonEdit = connect(mapStateToProps, {
  setPreviewFile,
  editSecondaryWorkerInfo,
})(KadriCardsPersonEdit);
