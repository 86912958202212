import React, {useEffect, useRef, useState} from "react";
import "./assets/style/EditRent.css";

import Modal from '../../components/modals/modalComponents/Modal/Modal';
import {MButtonGreen} from "../../styledComponents/Buttons"
import close from "../../assets/close.svg";
import {useFormik} from "formik";
import EditRentInfo from "./EditRent/EditRentInfo";
import EditRentWashContract from "./EditRent/EditRentWashContract";
import {Tab, TabList, TabPanel, Tabs} from "react-tabs";
import moment from "moment";
import EditRentPartiesAgreement from "./EditRent/EditRentPartiesAgreement";
import EditRentResponsiblePersons from "./EditRent/EditRentResponsiblePersons";
import loadEmployers from "../../redux/actions/employers/loadEmployers";
import * as Yup from 'yup';
import {useDispatch, useSelector} from "react-redux";
import EditRentDocument from "./EditRent/EditRentDocument";
import EditRentHistory from "./EditRent/EditRentHistory";
import {useAlert} from "react-alert";
import loadUserData from "../../redux/actions/loadUserData";
import {getCookie} from "../../utilits/cookies";
import EditRentStatistic from "./EditRent/EditRentStatistic";
import EdiRentController from "./EditRent/EditRentController";
import ControllerAddUser from "./modal/controllerAddUser";
import addAndLoadUserController from "../../redux/actions/controller/addUserController";
import loadControllerIp from "../../redux/actions/controller/loadControllerIp";
import loadControllerVisitor from "../../redux/actions/controller/visitor/load";
import {LOAD_INFO_CONTROLLER} from "../../redux/types";

const customEqual = (oldValue, newValue) => JSON.stringify(oldValue) === JSON.stringify(newValue)
const EditRent = (props) => {
  const dispatch = useDispatch();
  const {setEditObject, saveObject, objectData, object, user, indexPageTab} = props;
  const [indexTab, setTabIndex] = useState(indexPageTab);
  const controller = useSelector(state => state.controller.listIp, customEqual)
  let isObject = controller && controller.some(el => el.objectId === objectData._id)
  const info = useSelector(state => state.controller.info, customEqual)
  const userList = useSelector(state => state.controller.usersController, customEqual)

  const [isModalAdd, setIsModalAdd] = useState(true);
  const [role, setRole] = useState(null);
  const [model, setModel] = useState(null);
  const [editVisitorId, setVisitorId] = useState(null);
  const [tabLengths, setTabLength] = useState(0);
  const [tabSubmit, setTabSubmit] = useState(false);
  const [isAddress, setAddress] = useState(false)
  const [isValid, setValid] = useState(false);
  const tabsRef = useRef();
  const alert = useAlert()
  const alertFunc = (message, type) => {
    alert.show(message, {type: type})
  }
  const token = getCookie("token")
  const validationSchema = Yup.object().shape({
    houseNumber: Yup.string()
      .max(10, 'Досягнуто ліміт символів')
      .required('Вкажіть номер будівлі'),
    address: Yup.string()
      .max(200, 'Досягнуто ліміт символів')
      .required('Вкажіть адресу'),
    region: Yup.string().required('Виберіть регіон'),
    code: Yup.string().required('Вкажіть код об’єкту'),
    typeObject: Yup.string().required('Виберіть тип об’єкта'),
    dateStart: Yup.string().required('Вкажіть дату'),
    dateEnd: Yup.string().required('Вкажіть дату'),
    email: Yup.string().email('Недійсна електронна адреса').required('Вкажіть email'),
    landlord: Yup.array().min(1, 'Обовязкове до заповненя "Мережа ECONOMCLASS"').required('Обовязкове до заповненя'),
  });

  const {
    values,
    handleSubmit,
    setFieldValue,
    handleChange,
    touched,
    setValues,
    errors,
    handleBlur,
    submitForm
  } = useFormik({
    initialValues: objectData,
    validationSchema,
    onSubmit: async () => {
      values.reference = `${values.region + moment(values.dateStart).format("DDMMYYYY") + values.typeObject}`;
      await saveObject(values).then(async res => res.success && await dispatch(loadUserData(token)));
      await dispatch(loadUserData(token))
    },
  });

  useEffect(() => {
    tabsRef.current && setTabLength(tabsRef.current.props.children.length - 1)
    dispatch(loadEmployers(alertFunc))
    dispatch(loadControllerIp(false, objectData._id))
  }, [])
  useEffect(() => {
    isObject && dispatch(addAndLoadUserController(values.landlord, objectData._id))
  }, [isObject])

  useEffect(() => {
    validationSchema.isValidSync(values) ? setTabSubmit(false) : setTabSubmit(true)
  }, [errors])
  const addressValid = object.filter(el => el._id !== values._id).some(el => el.address === values.address && el.houseNumber === values.houseNumber);
  useEffect(() => {
    if (addressValid) {
      alertFunc("Об’єкт з такою адресою вже існує", 'error')
      setAddress(true)
    } else {
      setAddress(false)
    }
  }, [values])
  useEffect(() => {
    setValues(objectData);
  }, [objectData]);
  const toggleModal = () => {
    setEditObject(null)
    localStorage.setItem("scrollCord", JSON.stringify({
      scrollX: 0,
      scrollY: 0
    }));
    dispatch({
      type: LOAD_INFO_CONTROLLER,
      payload: null
    })
  };
  const nextTab = (e) => {
    e.preventDefault();

    if (tabLengths > indexTab) {
      if (isValid && !isAddress && indexTab === 0) {
        setTabIndex(indexTab + 1);
      } else if (!tabSubmit && indexTab >= 1) {
        setTabIndex(indexTab + 1);
      }
      tabSubmit && submitForm();
      if (addressValid) {
        alertFunc("Об’єкт з такою адресою вже існує", 'error')
      }
    }
    if (indexTab === 1 && !errors.dataEnd && !errors.dataStart) {
      setTabIndex(indexTab + 1);
    }
  }

  const ErrorMessage = ({name}) => {
    return errors[name] && touched[name] ? <div className="error">{errors[name]}</div> : null
  }

  console.log(values)
  function onKeyDown(keyEvent) {
    if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
      keyEvent.preventDefault();
    }
  }

  const handleSelect = (index) => {
    setTabIndex(index)
    if (index) {
      dispatch(loadControllerVisitor(objectData._id))
    }
  }

  const indexValid = () => {
    if (indexTab <= 1) {
      (values.dateEnd !== "" && values.dateStart !== "" && (values.code !== "" && values.code)) && setTabIndex(2)
    }
  }
  return (
    <>
      {isModalAdd ?
        <Modal title='Дані об’єкта' closeIcon={close} className="edit_rent__modal" divider={false} style={{
          overflowY: "hidden"
        }} toggleFunc={toggleModal}>
          <Tabs selectedIndex={indexTab} onSelect={index => handleSelect(index)}>
            <TabList ref={tabsRef} className="edit_rent__menu">
              <Tab className="edit_rent__item" disabled={false}>
                Загальна інформація
              </Tab>
              <Tab className="edit_rent__item" disabled={!isValid}>
                Умови договору
              </Tab>
              <Tab className="edit_rent__item" disabled={tabSubmit} onClick={indexValid}>
                Сторони договору
              </Tab>
              <Tab className="edit_rent__item" disabled={tabSubmit}>
                Відповідальні особи
              </Tab>
              <Tab className="edit_rent__item" disabled={tabSubmit}>
                Документи
              </Tab>
              <Tab className="edit_rent__item" disabled={tabSubmit}>
                Статистика
              </Tab>
              {isObject && <Tab className="edit_rent__item" disabled={tabSubmit}>
                Контроль доступу
              </Tab>}
              <Tab className="edit_rent__item" disabled={tabSubmit}>
                Історія
              </Tab>
            </TabList>
            <form onKeyDown={onKeyDown} action="#" method="post" className='edit_rent__form'
                  onSubmit={handleSubmit} autoComplete='off'>
              <TabPanel className="edit_rent__tab">
                <EditRentInfo values={values} ErrorMessage={ErrorMessage} handleBlur={handleBlur}
                              handleChange={handleChange}
                              errors={errors}
                              setValid={setValid}
                              touched={touched}
                              setFieldValue={setFieldValue}/>
              </TabPanel>
              <TabPanel className="edit_rent__tab">
                <EditRentWashContract dateStartValue={values.dateStart} dateEndValue={values.dateEnd}
                                      handleChange={handleChange}
                                      id={objectData._id}
                                      ErrorMessage={ErrorMessage}
                                      prolongationValue={values.prolongation}
                                      setFieldValue={setFieldValue}
                                      monthValue={values.month}/>
              </TabPanel>
              <TabPanel className="edit_rent__tab">
                <EditRentPartiesAgreement ErrorMessage={ErrorMessage} landlordValue={values.landlord}
                                          lesseeValue={values.lessee}
                                          setFieldValue={setFieldValue}/>
              </TabPanel>
              <TabPanel className="edit_rent__tab">
                <EditRentResponsiblePersons setFieldValue={setFieldValue} regionalManagerValue={values.regionalManager}
                                            region={values.region} managerValue={values.manager}
                                            accountantValue={values.accountant} landlordValue={values.landlord}/>
              </TabPanel>
              <TabPanel className="edit_rent__tab edit_rent__tab--document"><EditRentDocument id={objectData._id}
                                                                                              house={values.houseNumber}
                                                                                              address={values.address}
                                                                                              region={values.region}/></TabPanel>
              <TabPanel className="edit_rent__tab"><EditRentStatistic id={objectData._id}
                                                                      ErrorMessage={ErrorMessage}
                                                                      update={submitForm}
                                                                      setFieldValue={setFieldValue}
                                                                      positionList={values.position}
                                                                      landlordValue={values.landlord}
                                                                      docPersonal={objectData.docPersonal}/></TabPanel>
              {isObject &&
                <TabPanel className="edit_rent__tab scroll-controller edit_rent__tab__controller"><EdiRentController
                  userList={userList} info={info}
                  setRole={setRole}
                  setModel={setModel}
                  objectId={objectData._id}
                  setVisitorId={setVisitorId}
                  setIsAddModal={setIsModalAdd}/></TabPanel>}
              <TabPanel className="edit_rent__tab"><EditRentHistory history={objectData.history}/></TabPanel>
              <div className='edit_rent__row edit_rent__footer'>
                {indexTab <= tabLengths - 2 && <MButtonGreen onClick={nextTab}>
                  Далі
                </MButtonGreen>}
                {indexTab <= tabLengths - 3 &&
                  <MButtonGreen disabled={tabSubmit || isAddress} type="submit">Зберегти</MButtonGreen>}
              </div>
            </form>
          </Tabs>
        </Modal> :
        <ControllerAddUser userList={userList} model={model} setVisitorId={setVisitorId} visitorId={editVisitorId}
                           role={role}
                           setIsModalAdd={setIsModalAdd} objectId={objectData._id}/>
      }
    </>
  );
};

export default EditRent
